






























import MNotificationVue from "@/mixins/MNotification.vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@service/contact.service";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { debounceProcess } from "@/helpers/debounce";
import { ContactData, ResponseListContactData } from "@interface/contact.interface";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";
export default Vue.extend({
  name: "CSelectCustomerCode",
  mixins: [
    MNotificationVue,
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: undefined
    },
    propSearchBy: {
      type: String,
      default: undefined,
    },
  },
  data() {
    this.searchCustomer = debounceProcess(this.searchCustomer, 300);
    return {
      loading: false,
      optCustomer: [] as IOption<ContactData>[],
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        search: "customer~true_AND_active~true",
        sorts: "firstName:asc"
      },
      dtCustomers: {} as ResponseListContactData,
    };
  },
  created() {
    if (this.propSearchBy) this.queryParams.search = `active~true_AND_${this.propSearchBy}`;
    this.getList(this.queryParams);
  },
  methods: {
    getListCustomer(params: RequestQueryParamsModel): Promise<ResponseListContactData> {
      return contactServices.listContactData(params);
    },
    onSelect(e: string, meta: ContactData): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
    searchCustomer(search = ""): void {
      const searchBy: string[] = ["customer~true_AND_active~true"];
      let findBy = "";
      if (search) {
        searchBy.push(`customerNumber~*${search}*`);
        findBy = searchBy.join("_AND_");
      } else {
        findBy = searchBy.join();
      }
      this.queryParams.page = 0;
      this.queryParams.search = findBy;
      this.optCustomer = [];
      this.getList(this.queryParams);
    },
    popupScroll(e): void {
      if ((this.dtCustomers.totalPages - 1) === this.dtCustomers.currentPage) return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListCustomer(params);
        const { optCustomer } = this;
        const opts = res.data.map(x => ({ key: x.customerNumber, value: x.customerNumber, meta: x }));
        this.optCustomer = [...optCustomer, ...opts];
        this.dtCustomers = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    async handleMissingOpts(): Promise<void> {
      try {
        if (this.forList || !this.value) return;
        const opt = this.optCustomer.find(x => x.value === this.value);
        if (!opt) {
          const { data } = await this.getListCustomer({ search: `secureId~${this.value}` });
          const newOpt = { key: data[0].fullName, value: data[0].id, meta: data[0] };
          const { optCustomer } = this;
          this.optCustomer = [...optCustomer, newOpt];
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
  },
});
